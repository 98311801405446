/**
 * 批量管理api接口统一管理
 */
import { get, post } from '../https.js'

/**
  * 腾讯3.0创建腾讯广告计划 
  * @param {*} data
  * @returns
  */
 export const createTencentPlan = (data) => post('/v3/tencent/createTencentPlan', data)

/**
  * 腾讯3.0验证定向包是否能使用 
  * @param {*} data
  * @returns
  */
 export const testProject = (data) => get('/v3/tencent/testProject', data)


/**
  * 腾讯3.0获取产品 
  * @param {*} data
  * @returns
  */
 export const marketingTargetAssetsGet = (data) => get('/v3/tencent/marketingTargetAssetsGet', data)


/**
  * 对应的事朋友圈文字链 
  * @param {*} data
  * @returns
  */
 export const selectPengyouQuan = (data) => get('mediaDictonary/selectPengyouQuan', data)

/**
  * 浮层卡片按钮文案（广告计划-MP） 
  * @param {*} data
  * @returns
  */
 export const selectFuCengCardText = (data) => get('/mediaDictonary/selectFuCengCardText', data)



/**
  * 查询adq子账户信息
  * @param {*} data
  * @returns
  */
export const SelectAlladqSonAccount = (data) => get('adq/SelectAlladqSonAccount', data)


/**
  * Adq推广目标查询
  * @param {*} data
  * @returns
  */
export const selectAdqCAMPAIGNTYPENORMAL = (data) => get('/mediaDictonary/selectAdqCAMPAIGNTYPENORMAL', data)


/**
  * 获取商品库（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectproductCatalogsGet = (data) => get('/mediaDictonary/selectproductCatalogsGet', data)

/**
  * 获取商品（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectproductItemsGet = (data) => get('/mediaDictonary/selectproductItemsGet', data)

/**
  * 出价方式（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectBidMode = (data) => get('/mediaDictonary/selectBidMode', data)
/**
  * 出价类型（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectSMARTBIDTYPE = (data) => get('/mediaDictonary/selectSMARTBIDTYPE', data)
/**
  * 优化目标（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectOPTIMIZATIONGOAL = (data) => get('/mediaDictonary/selectOPTIMIZATIONGOAL', data)
/**
  * 出价策略（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectBIDSTRATEGY = (data) => get('/mediaDictonary/selectBIDSTRATEGY', data)
/**
  * 获取人群包（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectCustomAudiencesGet = (data) => get('/mediaDictonary/selectCustomAudiencesGet', data)


/**
  * 对接编辑
  * @param {*} data
  * @returns
  */
export const select = (data) => get('/plan/select', data)

/**
  * sdfdsfsdfsdsdfsdfsfsdf
  * @param {*} data
  * @returns
  */
export const upde = (data) => get('/brand/test', data)

/**
  * 创建广告计划
  * @param {*} data
  * @returns
  */
export const createPlan = (data) => post('/plan/createPlan', data)

/**
  * 获取数据源（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const selectUserActionSetsGet = (data) => get('/mediaDictonary/selectUserActionSetsGet', data)

/**
  * 获取数据源（广告组-Adq）V3
  * @param {*} data
  * @returns
  */
 export const userActionSetsget = (data) => get('/v3/tencent/userActionSetsget', data)

/**
  * 获取策略（广告组-Adq）
  * @param {*} data
  * @returns
  */
 export const selectLandingPageSellStrategyGet = (data) => get('/v1/Mp/selectLandingPageSellStrategyGet', data)


 
 /**
  * 创建策略（广告组-Adq）
  * @param {*} data
  * @returns
  */
  export const creadLandingPageSellStrategyGet = (data) => post('/v1/Mp/creadLandingPageSellStrategyGet', data)


  /**
  * 新增转化归因（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const createConversions = (data) => post('/v1/Mp/createConversions', data)
 

  /**
  * 获取转化归因（广告组-Adq）
  * @param {*} data
  * @returns
  */
export const conversionsGet = (data) => get('/v1/Mp/conversionsGet', data)
   

  /**
  * 获取转化归因V3（广告组-Adq）
  * @param {*} data
  * @returns
  */
   export const conversionsGetV3 = (data) => get('/v3/tencent/conversionsGet', data)

/**
  * 查询品牌
  * @param {*} data
  * @returns
  */
export const selectBrand = (data) => get('/brand/selectBrand', data)

/**
  * 查询浮层卡片
  * @param {*} data
  * @returns
  */
export const selectFuCengCard = (data) => get('/brand/selectFuCengCard', data)

/**
  * 查询图文卡片
  * @param {*} data
  * @returns
  */
export const selectImageText = (data) => get('/brand/selectImageText', data)
 
/**
  * 获取原生推广页（广告创意-Adq）
  * @param {*} data
  * @returns
  */
 export const selectWechatPagesGet = (data) => get('/v1/Mp/selectWechatPagesGet', data)

 /**
  * 获取官方落地页
  * @param {*} data
  * @returns
  */
 export const selectPagesGet = (data) => get('/v3/tencent/selectPagesGet', data)


/**
  * 按钮文案（广告创意-Adq）
  * @param {*} data
  * @returns
  */
export const selectbuttonText = (data) => get('/mediaDictonary/selectbuttonText', data)

/**
  * 获取视频号（广告创意-Adq）
  * @param {*} data
  * @returns
  */
 export const selectPromotedObjectsGet = (data) => get('/v1/Mp/selectPromotedObjectsGet', data)

/**
  * 获取视频号（广告创意-Adq）V3
  * @param {*} data
  * @returns
  */
 export const selectPromotedObjectsGetV3 = (data) => get('/v3/tencent/selectPromotedObjectsGet', data)


/**
  * 查询adq模板
  * @param {*} data
  * @returns
  */
export const selectMediaModel = (data) => get('/plan/selectMediaModel', data)

/**
  * 设为常用模板
  * @param {*} data
  * @returns
  */
export const updateMediaModel = (data) => get('/plan/updateMediaModel', data)

// 新增数据表
export const insetReportFormsList = (data) => post('other/insetReportFormsList', data)

/**
  * 数据表查询请求参数
  * @param {*} data
  * @returns
  */
export const selectReportFormsRequestList = (data) => get('other/selectReportFormsRequestList', data)

/**
  * 查询数据列表
  * @param {*} data
  * @returns
  */
export const selectReportFormsList = (data) => get('other/selectReportFormsList', data)

/**
  * 查询用户
  * @param {*} data
  * @returns
  */
export const selectReportFormsUserList = (data) => get('other/selectReportFormsUserList', data)

// ===================================百度==================================

/**
  * 查询所有超管百度账号
  * @param {*} data
  * @returns
  */
export const baiduAccountSelectAll = (data) => get('baidu/baiduAccountSelectAll', data)

// ===================================百度end=================================


/**
  * 创建mp批量
  * @param {*} data
  * @returns
  */
 export const createMpPlan = (data) => post('/v1/Mp/createMpPlan', data)


