// 引入axios
import axios from 'axios'
// 引入qs模块 用来序列化post类型的数据
import QS from 'qs'
// import router from '../router/index'


import { ElLoading } from 'element-plus'
import domMessage from './messageOnce'
import router from '@/router/index.js'
// 判断是否是开发环境
process.env.NODE_ENV === 'development'
  ? 
  (axios.defaults.baseURL = 'http://dfrt.natapp4.cc') // 文龙
  // (axios.defaults.baseURL = 'http://wxgzhapp.natapp1.cc') // 黄彬
  : (axios.defaults.baseURL = 'https://wxappvue.df01.top/api')

// 全局loading
let loadingInstance = {}

// 设置请求超时
axios.defaults.timeout = 3000000

// Content-Type: application/json ： 请求体中的数据会以json字符串的形式发送到后端
// Content-Type: application/x-www-form-urlencoded：请求体中的数据会以普通表单形式（键值对）发送到后端
// Content-Type: multipart/form-data： 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件。
// post请求头的设置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 设置请求头带凭证
// 错误提示
const messageOnce = new domMessage()
const tip = (msg) => {
  messageOnce.error(msg.msg)
}

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers.authorize = 'Bearer' + localStorage.getItem('token');
    loadingInstance = ElLoading.service({ fullscreen: true, text: '努力加载中~' })
    return config
  },
  (error) => {
    loadingInstance.close()
    return Promise.error(error)
  }
)
axios.interceptors.response.use(response => {
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  loadingInstance.close()
  // if (response.config.url == '/excel/collectData') {
  //   console.log('进1111')
  //   axios.defaults.responseType = 'blob'
  //   return Promise.resolve(response)
  // } else
  if (response.data.code === 200) {
    return Promise.resolve(response)
  } else if (response.data.code === 401) {
    localStorage.removeItem('token')
    router.push('/')
    return tip(response.data)
  } else if (response.data.type == 'application/octet.stream') {
    return Promise.resolve(response)
  } else {
    return tip(response.data)
  }
}, error => {
  tip('请求失败，请联系管理员')
  loadingInstance.close()
})
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      // .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

// const showStatus = (status) => {
//   let message = ''
//   switch (status) {
//     case 400:
//       message = '请求错误(400)'
//       break
//     case 401:
//       message = '未授权，请重新登录(401)'
//       break
//     case 403:
//       message = '拒绝访问(403)'
//       break
//     case 404:
//       message = '请求出错(404)'
//       break
//     case 408:
//       message = '请求超时(408)'
//       break
//     case 500:
//       message = '服务器错误(500)'
//       break
//     case 501:
//       message = '服务未实现(501)'
//       break
//     case 502:
//       message = '网络错误(502)'
//       break
//     case 503:
//       message = '服务不可用(503)'
//       break
//     case 504:
//       message = '网络超时(504)'
//       break
//     case 505:
//       message = 'HTTP版本不受支持(505)'
//       break
//     default:
//       message = `连接出错(${status})!`
//   }
//   return tip(`${message}，请检查网络或联系管理员！`)
// }
