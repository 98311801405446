import { get, post } from '../https.js'

// 同步视频到剪辑素材库
export const syncTitleGroup = (data) => post('/clipLibrary/syncTitleGroup', data)

// 查询剪辑素材库
export const selectClipLibraryAll = (data) => get('/clipLibrary/selectClipLibraryAll', data)


// 批量推送视频
export const batchPushVideo = (data) => post('/clipLibrary/batchPushVideo', data)

// 批量删除素材
export const batchDeleteClipLibrary = (data) => post('/clipLibrary/batchDeleteClipLibrary', data)

// 批量刷新素材
export const batchRefreshVideoUrl = (data) => post('/clipLibrary/batchRefreshVideoUrl', data)







